<template>
    <div class="storeInfo">
        <van-field name="radio" label="门店类型：">
            <template #input>
                <van-radio-group v-model="form.type" direction="horizontal">
                    <van-radio name="1" style="margin-bottom:10px">美食</van-radio>
                    <van-radio name="2" style="margin-bottom:10px">酒店/民宿</van-radio>
                    <van-radio name="3" style="margin-bottom:10px">休闲/娱乐</van-radio>
                    <van-radio name="4" style="margin-bottom:10px">周边游/旅游</van-radio>
                </van-radio-group>
            </template>
        </van-field>
        <van-field v-model="form.contactName" label="联系人" placeholder="请输入门店联系人姓名" />
        <van-field v-model="form.contactMobile" type="tel" label="联系人手机号" placeholder="请输入门店联系人手机号" />
        <van-field name="uploader" label="门店封面" :max-size="1 * 1024 * 1024" @oversize="onPicOversize">
            <template #input>
                <van-uploader v-model="topPic" :max-count="1" class="coverPic" :after-read="handlePicsc">
                    <div class="cover1"></div>
                </van-uploader>
            </template>
        </van-field>

        <van-field name="uploader" label="门店视频">
            <template #input>
                <van-uploader v-model="topVideo" :max-size="10 * 1024 * 1024" @oversize="onOversize"
                    :after-read="handleVideosc" max-count="1" accept="video/*" class="coverVideo">
                    <div class="cover1"></div>
                    <div slot="preview-cover">
                        <video class="videoClass" :src="videoUrl"
                            v-if="videoUrl"></video>
                    </div>
                </van-uploader>
            </template>
        </van-field>

        <van-field name="uploader" label="门店合图">
            <template #input>
                <van-uploader v-model="form.bottomPic" max-count="9" :after-read="handleBottomPic"
                    :max-size="1 * 1024 * 1024" @oversize="onPicOversize">
                </van-uploader>
            </template>
        </van-field>

        <div class="footer">
            <div v-if="!isEdit">
                <van-button block :loading="btnLoading" type="info" loading-text="加载中..." @click="sureBtnClick()">添加
                </van-button>
            </div>
            <div v-if="isEdit">
                <van-button block :loading="btnLoading" type="info" loading-text="加载中..." @click="sureBtnClick()">编辑
                </van-button>
            </div>
        </div>
    </div>
</template>



<script>
import { Toast } from 'vant';
import { alcoss } from '@/api/http';
export default {
    

    data() {

        return {
            btnLoading: false,
            isEdit:false,//修改 或 添加
            
            dataArr: [],
            quarmas: {
                pageNum: 1,
                pageSize: 10,
            },
            beginTime: "开始时间",
            endTime: "结束时间",
            time: true,
            topVideo: [],
            videoUrl:null,//展示的视频
            topPic:[],//展示的
            form: {
                type: null,
                contactName: null,
                contactMobile: null,
                topPic:null,
                bottomPic: [],
                topVideo:null,//不为空时 有视频
            }
 

        };
    },
    created() {

        this._canSure = true
        this._Id = this.$route.query.Id
        this.storeId = this.$route.query.storeId
        if (this.$route.query.isEdit) {
            this.isEdit = true
            this.$http.getAdcStoreInfo({ storeId: this.storeId }).then(res => {
                if (res.code == 200) {
                    var arr = res.data;
                    if (arr.length > 0) {
                        var data = arr[0]
                        this.form.type = data.type+''
                        this.form.contactMobile = data.contactMobile
                        this.form.contactName = data.contactName
                        this.form.topPic = data.topPic
                        this.topPic = [{ url: data.topPic }]
                        if (data.topVideo) {
                            this.videoUrl = data.topVideo
                            this.topVideo = [{url:data.topVideo}]
                        }
                        if (data.bottomPic) {
                            var picArr = data.bottomPic.split(',')
                            var picArr1 = []
                            picArr.forEach(item => {
                                picArr1.push({url:item})

                            })
                            this.form.bottomPic = picArr1
                        }



                    }
                    console.log('门店信息',res);
                }
            })
        }
        var merchant = localStorage.getItem('xxMerchantInfo')
        this.merchantId = JSON.parse(merchant).merchantId
        console.log('店铺=', this.storeId, JSON.parse(merchant))
    },
    mounted() {

        
    },

    methods: {
        async handleVideosc(file) {
            this.videoUrl = file.content
            file.status = 'uploading';
            file.message = '上传中...';
            let url = await alcoss("storeNew/" + this.merchantId + '/' + this.storeId + '/', `${file.file.name}`, file.file);
            file.status = 'done';
            this.form.topVideo = url.url
        },
        async handlePicsc(file) {
            file.status = 'uploading';
            file.message = '上传中...';
            let url = await alcoss("storeNew/" + this.merchantId + '/' + this.storeId + '/', `${file.file.name}`, file.file);
            file.status = 'done';
            this.form.topPic = url.url
            console.log('上部图片', file,url)  
        },
        async handleBottomPic(file) {
            console.log('底部图片', file)
            file.status = 'uploading';
            file.message = '上传中...';
            let url = await alcoss("storeNew/" + this.merchantId + '/' + this.storeId + '/', `${file.file.name}`, file.file);
            file.status = 'done';
            file.url = url.url   
 
            
        },
        onOversize() {
            Toast('文件大小不能超过 10M');
            
        },
        onPicOversize() {
            Toast('图片大小不能超过 1M');
        },

        sureBtnClick() {
            if (!this.form.type) {
                Toast('请选择门店类型')
                return
            }
            if (!this.form.contactName) {
                Toast('请输入门店联系人姓名');
                return
            }
            if (!this.form.contactMobile) {
                Toast('请输入门店联系人手机号');
                return
            }
            // if (this.form.contactMobile.length != 11) {
            //     Toast('请输入格式正确的手机号');
            //     return
            // }
            if (!this.form.topPic) {
                Toast('请设置门店封面');
                return
            }

            var params = { ...this.form }
            var picArr = []
            if (this.form.bottomPic.length > 0) {
                for (var i = 0; i < this.form.bottomPic.length; i++){
                    var file = this.form.bottomPic[i]
                    if (file.url) {
                        picArr.push(file.url);
                    }
                }
            }
            if (this.topVideo.length > 0) {
                // params.topVideo = this.videoUrl;
            } else {
                params.topVideo = '';
            }
            var picStr = ''
            if (picArr.length > 0) {
                picStr = picArr.toString()
            }
            params.bottomPic = picStr;
            params.storeId = this.storeId;
            if (this.isEdit) {
                params.id = this._Id
                this.$http.updateAdcStoreInfo(params).then(res => {
                    if (res.code == 200) {
                        Toast.success('编辑成功')
                        this.$router.go(-1)
                        return
                    }
                    Toast(res.code + ':'+res.message)
                })
                return
            } 
            if (!this._canSure) {
                return
            }
            this._canSure = false
            this.$http.addAdcStoreInfo(params).then(res => {
                this._canSure = true
                if (res.code == 200) {
                    this.$router.go(-1)
                    return
                }
                Toast(res.code + ':' + res.message)
            })
            
        }
    },
};
</script>
<style lang="scss">
.coverPic {
   .van-uploader__preview-image {
        width: 200px;
        height: 150px;
        border: 1px dashed rgba(66, 155, 238, 1);
        border-radius: 10px;
   }


}
.coverVideo{
    .van-uploader__file {
        width: 200px;
        height: 150px;

    }


}

</style>
<style lang="scss" scoped>
.flex-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}



.storeInfo {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background: white;
    padding: 20px 0px;
    box-sizing: border-box;
    .item1{
        height: 40px;
        font-size: 14px;
    }

    .cover1{
        width: 200px;
        height: 150px;
        border: 1px dashed rgba(66, 155, 238, 1);
        border-radius: 10px;
        
    }
    .videoClass{
        width: 200px;
        height: 150px;
        object-fit: cover;
    }

    .footer{
        padding: 20px 15px;
        box-sizing: border-box;
    }

}
</style>